import Layout from '../components/layout'
import { useRouter } from 'next/router';
import getErrorLocales from '../locales/locales_error';
import getCommonLocales from "../locales/locales_common";
import OpenGraphTwitterCard from "../components/seo/OpenGraphTwitterCard";
import { IMAGE_FEATURES, VIDEO_FEATURES } from '../components/Workspace/Features/constants';
import FeatureLink from '../components/Workspace/Features/FeatureLink';
import Link from 'next/link';

const orStyle = { textAlign: "center", fontSize: "19px", }


export default function ErrorPage() {
  const { locale, pathname } = useRouter();
  const dictionary = { ...getErrorLocales(locale), ...getCommonLocales(locale) };

  const pageTitle = dictionary['404 | This page could not be found'];
  const mainClassName = 'error_page';
  const errorText = pageTitle;

  return (
    <Layout mainClassName={mainClassName}>
      <OpenGraphTwitterCard title={pageTitle} description={''} url={pathname} noindex={true} />
      <section className="error_page_item">
        <h1 className="h1">{errorText}</h1>
        <Link href="/" className='btn' >
          {dictionary['Home']}
        </Link>
        <p style={orStyle}>{dictionary["or"]}</p>

        <br />
        <div style={{ display: 'flex', flexWrap: 'wrap', width: '90%', maxWidth: '1200px', margin: '0 auto 120px', gap: "20px", justifyContent: 'center' }}>
          {[...VIDEO_FEATURES, ...IMAGE_FEATURES].map((feature) => (
            <FeatureLink key={feature.name} feature={feature} dictionary={dictionary} />
          ))}
        </div>
      </section>
    </Layout>
  );
}
