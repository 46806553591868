export const VIDEO_FEATURES = [
	{ name: "background_remover", description: "background_remover_description", link_url: "/remove-video-bg/", src_url: "/workspace/features/v1.png", },
	{ name: "Turn photo into video", description: "photo_into_video_description", link_url: "/turn-photo-into-video/", src_url: "/workspace/features/v2.png", },
];
export const IMAGE_FEATURES = [
	{ name: "page_illustration_h1", description: "page_illustration_descriptor", link_url: "/illustration-drawing/", src_url: "/illustration_service/prew.png", },
	{ name: "interior_redesign", description: "page_interior_desc", link_url: "/interior-design-planner/", src_url: "/workspace/features/i5.png", },
	{ name: "image_generator", description: "create_image", link_url: "/text-to-image-generator/", src_url: "/workspace/features/i1.png", },
	{ name: "photo_into_painting", description: "get_artwork", link_url: "/photo-into-painting/", src_url: "/workspace/features/i2.png", },
	{ name: "erase_replace", description: "remove_or_replace", link_url: "/erase-and-replace/", src_url: "/workspace/features/i3.png", },
	{ name: "page_editor_h1", description: "page_editor_desc", link_url: "/editor/", src_url: "/image_editor/landing/entry.png", },
];
