import style from "./Features.module.scss";
import Link from "next/link";
import Image from "next/image";

function FeatureLink({ feature, dictionary, isBig = false }) {
	return (
		<Link
			key={feature.name}
			href={feature.link_url !== "soon" ? feature.link_url : "/"}
			className={`${style.features_item} ${feature.link_url === "soon" ? style.disabled : ""} ${isBig ? style.big : ""}`}
		>
			<Image className={style.item_img} src={feature.src_url} alt={feature.name} width={236} height={116} quality={90} style={{ objectFit: "contain" }} />
			<h3 className={style.item_title}>{dictionary[feature.name]}</h3>
			<p className={style.item_description}>{dictionary[feature.description]}</p>
			{feature.link_url === "soon" ? <span className={style.soon}>Soon</span> : null}
		</Link>
	);
}

export default FeatureLink;
